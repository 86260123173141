
.review_button {
  width: calc(100% - 65px);
  font-family: 'Inter', sans-serif;
  color: hsl(0, 0%, 100%);
  background-color: hsl(0, 0%, 0%);
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  margin-top: 40px;
  line-height: 50px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.review_title{
  border-bottom: black solid 1px;
  color: black;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 13px;
  letter-spacing: .3px;
  }

  .review_submit_body_container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .review_body_container2 {
    width: 100%;
  }

  .review_submit_body_container2 {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin-top: 20px;
  }

  