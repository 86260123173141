
.main_div{
  overflow-x: initial;
}

.main_div * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Inter', sans-serif;
}

.main_container{
  margin: 55px 110px 0;
}

.video_container{
  display: block;
  /* max-width: 968px; */
  margin-bottom: 60px;
  min-height: 227.81px;
  position: relative;
}

#video{
  object-fit: fill;
  width: 100%;
  border-radius: 3px;
  display: block;
  height: 600px;
}

.video_text{
  background-color: rgba(0, 0, 0, .25);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 17.65%)
}

.main_video_text{
  font-size: 38px;
  line-height: 46px;
  color: hsl(0, 0%, 100%);
  font-weight: 800;
  letter-spacing: 0;
}

.shop_now_button{
  margin-top: 21px;
  border-radius: 1px;
  background-color: hsl(0, 0%, 100%);
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 16px;
  color: var(--p);
  outline: 0;
  border: 0;
  padding: 16px 79px;
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
}

.bottom_text_video{
  display: block;
  padding: 15px;
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 100%);
  text-align: center;
  letter-spacing: 0;
  position: absolute;
  bottom: -46px;
  left: 0;
  font-size: 13px;
  line-height: 16px;
  margin-right: 53px;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.category_navigation_container{
  display: flex;
  justify-content: space-between;
  margin: 0px 110px;
}

.category_image_nav{
  width: 450px;
  border-radius: 2%;
}

.title_product_nav{
  font-family: 'inter', sans-serif;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
}

.category_product_nav{
  font-family: 'inter', sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  padding-top: 20px;
  color: rgb(121, 121, 121)
}