
.review_whole_container{
  background: white;
  position: relative;
  width: 100%;
  max-width: 540px;
  text-align: center;
  margin: 53px auto;
  }

  .what_people_text{
    font-family: 'Inter', sans-serif;
    display: block;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: .3px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .rating_review_text{
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    line-height: 37px;
  }

  .main_rating_visual_container{
    margin: auto 60px;
    padding: 0 19px 0 15px;
    height: 30px;
    border-radius: 15px;
    background-color: black;
    color: white;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .main_rating_star_inside_container{
    margin-right: 15px;
  }

  .main_rating_star{
    height: 13px;
  }

  .rating_number{
    font-size: 16px;
    font-weight: 700;
  }

  .users_review_main_container{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .all_users_review_text_container{
    margin-top: 13px;
    width: 100%;
    text-align: left;
  }

  .all_users_review_title{
    margin-top: 40px;
    margin-bottom: 39px;
    display: block;
    font-weight: 900;
    color: black;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
  }

  .individual_review_main_container{
    position: relative;
    margin-bottom: 40px;
    padding: 0 0 20px 61px;
    border-left: lightgrey 2px solid
  }

  .container_for_stars{
    position: relative;
    color: #a2a2a2;
  }

  .stars_position_container{
    display: inline-block;
    vertical-align: top;
  }

  .review_body_container{
    display: block;
    text-align: left;
    color: black;
    margin-top: 25px;
  }

  .review_main_text{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 26px;
    padding-bottom: 18px;
  }

  .review_author_container{
    display: block;
    text-align: left;
    color: black;
    margin-top: 25px;
    width: 100%;
    max-width: 100%;
  }

  .review_author_text{
    color: black;
    font-family: 'Inter', sans-serif;    
    font-weight: 800;
    font-size: 13px;
    letter-spacing: .3px;
    top: 1px;
    min-width: 0;
    vertical-align: top;
    float: left;
    margin-right: 5px;
    width: 100%;
    max-width: 100%;  
    padding-bottom: 10px;
    border-bottom: rgb(211, 211, 211, .5) 1px solid;
}

.stars_5{
  content:url('../../images/5_star.png');
}

.stars_4 {
  content: url('../../images/4_star.png');
}

.stars_3 {
  content: url('../../images/3_star.png');
}

.stars_2 {
  content: url('../../images/2_star.png');
}

.stars_1 {
  content: url('../../images/star.png');
  height: 25px;
}

.star_box_container{
  display: flex;
  justify-content: space-between;
}