#modal {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
}

#modal-background {
  position: fixed;
  /* z-index: 90; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  will-change: opacity;
  box-shadow: 0 100px #3c3c3d, 0 -100px #3c3c3d;
  background-color: rgba(0, 0, 0, .75);
}

#modal-content {
  position: absolute;
  background-color: white;
}