/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: thin;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
} */

ol{
  padding-inline-start: 0;
}

.tech_main_container{
  order: 3;
  margin-top: 74px;
  margin-left: 375px;
  margin-right: 375px;
  margin-bottom: 150px;
  }

  .tech_information_main_container{
    display: flex;
    text-align: center;
    flex-wrap: nowrap;
    margin-top: 30px;
    padding-bottom: 25px;
    overflow-x: scroll;
    scrollbar-width: thin
  }

  .softshell_info_container{
    margin-right: 30px;
    overflow: break-word;
  }

  .tech_title{
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    text-align: left;
    margin-top: 20px;
  }

  .softshell_text{
    text-align: left;
    max-width: 272px;
    line-height: 23px;
    font-family: 'Inter', sans-serif;
    font-size: 13px !important;
    font-weight: 300;
    margin-top: 19px;
  }

  .tech_information_main_container{
    display: flex;
  }

  .tech_info_icon_individual_container{
    display: flex;
    margin-top: 30px;
  }

  .icon_text{
    font-family: 'Inter', sans-serif;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 13px;
    font-weight: 200;
    margin-top: 3px;
    margin-left: 10px;
  }

  .icon_num{
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 200;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 8px;
    margin-left: 10px;

  }

.bottom_of_icon{
  margin-bottom: 60px;
}

