button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

img.logo{
  height: 40px;
  width: 92px;
}

.top_level{
  background: black;
}

.upper_top{
  display: flex;
  align-items: center;
  height: 40px;
  max-width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
}

.shipping{
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 12px;
  line-height: 15px;
  padding: 8px 0;
  margin-left: 43px;
}

.dope{
  display: block;
  margin-left: 20px;
  width: 100px;
}

.shipping > span{
  font-size: 14px;
}

.free{
  font-weight: 80px;
}

.login_button {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  color: hsl(0, 0%, calc(100% - 0%));
  line-height: 14px;
  display: block;
  padding: 8px 25px;
  opacity: .8;
}

.login_button:hover{
  opacity: 1.0;
}

.profile_button{
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  color: hsl(0, 0%, calc(100% - 0%));
  line-height: 14px;
  display: block;
  padding: 8px 25px;
  opacity: .8;
}

.profile_button:hover {
  /* color: white; */
  opacity: 1.0;
}

.profile_dropdown{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 30;
}

.profile_dropdown a{
  text-align: center;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  background-color: white;
  color: hsl(0, 0%, calc(17.65%), 0.8);
  width: 95.07px;
  height: 30px;
  border-bottom: 1px grey solid;
  border-left: 1px grey solid;
  border-right: 1px grey solid;
  
}

.profile_dropdown a:hover{
  color: hsl(0, 0%, 17.65%);
  background-color: lightgrey;
}

.profile_dropdown :first-child{
  margin-top: 4px;
  border-top: 1px grey solid ;
}

.header_space{
  height: 8px;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.profile_bar{
  position: relative
}

.sick_home {
  display: flex;
  align-items: center;
  max-width: 1514px;
  margin: 0px auto;
  border-top-left-radius: 10%;
  user-select: none;
  position: relative;
}

.sick_home a {
  background-color: transparent;
  border: 0;
  position: relative;
  outline: 0;
}

.sick_home nav{
  flex-grow: 1;
  text-align: center;
}

.gender_link_men{
  font-family: 'Inter', sans-serif;
  color: hsl(0, 0%, 17.65%);
  font-size: 17px;
  font-weight: 800;
  margin-right: 13px;
  line-height: 24px;
  }

.gender_link_women {
  font-family: 'Inter', sans-serif;
  color: hsl(0, 0%, 17.65%);
  font-size: 17px;
  font-weight: 800;
  margin-right: 13px;
  line-height: 24px;
}


.right_side_nav_bar{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 47px;
  gap: 0 10px;
  font-family: 'Inter', sans-serif;
  color: hsl(0, 0%, 17.65%);
  font-size: 14px;
  font-weight: 800;
}

.nav_button>svg{
  vertical-align: top;
  position: relative;
}

.nav_button {
  display: flex;
  text-decoration: none;
  color: hsl(0, 0%, 17.65%);
}


.gender_button_nav{
  position: relative;
}

.cartItem_quantity{
  min-width: 14px;
  min-height: 14px;
  background-color: blue;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  bottom: 13px;
  right: 41px;
  border-radius: 50%;
  border-width: 0;
  padding: 1.5px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  line-height: normal;
  box-sizing: content-box !important;
}