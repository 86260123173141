.searchbar-input {
  font-size: 17px;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid black;
  outline: none;
  padding: 5px;
  height: 20px;
  letter-spacing: 0.03em;
  margin-right: 5px;
  text-align: center;
}

.searchbar-icon {
  padding-right: 5px;
  padding-top: 5px;
}

.search-result-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  column-gap: 24px;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 4vh;
}

.search-header {
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  letter-spacing: 0.015em;
  font-family: 'inter', sans-serif;
}

.search-error {
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  color: #7eb0d7;
  font-family: 'inter', sans-serif;
  height: 450px;
}

@media all and (max-width: 1200px) {

  #search-input,
  #search-icon {
    display: none;
  }
}