.product_show_outside_container{
  height: auto !important;
  display: block;
  overflow-y: visible;
  overflow-x: visible;
  background-color: hsl(0, 0%, 100%);
}

.product_show_main_container{
  width: 964px;
  margin: 0 auto;
}

.product_show_main{
  display: block;
  position: relative;
  font-weight: 400;
  margin: 70px auto 0;
}

.product_page_name{
  display: inline;
  font-size: inherit;
  font-weight: 800;
}

.product_info_container{
  position: relative;
  cursor: grab;
  transition: .2s;
  max-width: 964px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.product_image_outer_container{
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 0;
  transform: translateX(-402px);
  width: 1004px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.inner_container{
  display: inline-block;
  width: 502px;
  margin-right: 0;
  margin-left: 400px
}

.change_image_div{
  border-radius: 3px;
  overflow: hidden;
  padding-top: calc(117.675%);
  position: relative;
  background-color: hsl(0, 0%, calc(89.02%));
}

.image_container{
  width: 502px;
  height: 590.728px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
}

.main_image{
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  background-color: hsl(0, 0%, calc(89.02%));
  vertical-align: top !important;
  height: auto !important;
  transition: 1s ease-in !important;
  min-width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  }
/* 
.swiper {
  width: 502px;
  height: 591px;
}

.swiper-slide{
  width:80%
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  Center slide text vertically
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  width: 50px;
  height: 50px;
  color: #fff;
  background: #007aff;
}

.swiper-pagination{
  width:50px;
  height:50px;
} */