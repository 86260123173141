
ul{
  padding-inline-start: 0px;
}
.main_side_container{
  padding: 0 30px 32.5px 30px;
  min-height: inherit;
  width: 393px;
  margin-left: 18px;
  left: 50%;
  right: inherit;
  position: absolute;
  top: 205px;
  box-shadow: 0 8px 20px hsla(0, 0%, 0%, .06);
  z-index: 30;
  display: block;
  background: hsl(0, 0%, calc(100%));
}

.side_bar_name_container{
  position: relative;
}

.side_bar_name{
  font-family: 'Inter', sans-serif;
  max-width: 230px;
  line-height: 31px;
  font-weight: 800;
  font-size: 20px;
  color: hsl(0, 0%, 20%);
  margin-top: 10px;
}

.side_bar_logo{
  font-family: 'Inter', sans-serif;
  margin-bottom: 30px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: .3px;
  line-height: 20px;
  height: 17px;
  margin-top: 7px;
  color: hsl(0, 0%, 17.65%);
  text-decoration: none;
}

.side_bar_review_main_container{
  position: absolute;
  margin-bottom: inherit;
  left: unset;
  right: 30px;
  top: 0;
}

.side_bar_review_styling{
  display: inline-block;
  margin-right: 0;
  cursor: pointer;
}

.review_star_image{
  height: 20px;
  width: 20px
}

.side_bar_color_title{
  height: 17px;
  width: 44px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
}

.side_bar_clicked_color{
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
  margin-top: 6px;
  height: 26px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
}

.side_bar_color_circle_main{
  position: relative;
  margin-right: 0;
  top: 20px;
}

.side_bar_color_container{
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* max-height: 46px; */
  height: 50px;
}

.color_container{
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 46px;
  min-width: 46px;
}

.future_onclick_for_color{
  position: relative;
  width: auto !important;
  display: block;
  height: 46px;
  -webkit-user-drag: none;
}

.check_mark{
  position: hidden;
  box-sizing: border-box;
  height: 5px;
  width: 9px;
  border: 2px solid hsl(0, 0%, 100%);
  border-top: 0;
  border-right: 0;
  right: 5.9px;
  top: 7px;
  z-index: 2;
  transform: rotate(-45deg);
}

/* .future_onclick_for_color::after {
  content: "";
  display: inline-block;
  display: hidden;
  background: hsl(0, 0%, 17.65%);
  position: absolute;
  right: 0;
  top: 0;
  color: hsl(0, 0%, 100%);
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  line-height: 20.5px;
  width: 19px;
  height: 19px;
  border: solid 1px hsl(0, 0%, 17.65%);
} */

.color_image_styling{
  width: 48px;
  height: 48px;
  max-height: 46px;
  position: relative;
  overflow: hidden;
  border-radius: 50% !important;
  transition: none !important;
  /* border: solid 2px hsl(0, 0%, 100%); */
  opacity: 1;
}

.color_image{
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.side_bar_line_container{
  display: block;
  margin: 40px 0;
  overflow: hidden;
}

.line{
  display: block;
  position: static;
  content: "";
  height: 0.5px;
  border-bottom: solid 0.5px hsl(0, 0%, 92.94%);
  width: 100%;
}

.side_bar_size_circle_main{
  display: block;
  position: relative;
  line-height: 0;
}

.side_bar_size_circle_container{
  font-weight: 400;
  font-family: 'Inter', sans-serif;  
  margin-top: 0;
  min-height: 49px;
  display: block;
  width: 100%;
  position: static;
}

.size_button_nav{
  margin: 0;
  position: relative;
  left: -5px;
  width: calc(100% + 10px);
  padding: 0;
  min-height: 49px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.size_button{
  flex: initial;
  border-radius: 100%;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  background-color: rgb(236, 236, 236);
  border: solid 1px #f6f6f6;
  cursor: pointer;
  transition: .1s ease;
  line-height: 44px;
  width: 46px;
  max-width: 46px;
  height: 46px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 10px;
}

.side_bar_cart_favorite_main{
  margin-top: 50px;
}

.side_bar_shopping_cart_button{
  width: calc(100% - 65px);
  font-family: 'Inter', sans-serif;
  color: hsl(0, 0%, 100%);
  background-color: hsl(0, 0%, 0%);
  font-size: 14px;
  transition: opacity .2s ease-in-out;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.side_bar_shopping_cart_button svg{
  position: absolute;
  left: 18px;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  bottom: 15px
}

.cart_price_text{
  position: relative
}

.side_bar_favorite_button{
  float: right;
  width: 60px;
  color: hsl(0, 0%, 17.65%);
  background-color: hsl(0, 0%, 96.86%);
  font-size: 14px;
  transition: opacity .2s ease-in-out;
  text-decoration: none;
  font-family: 'Inter', sans-serif;  
  outline: 0;
  margin: 0;
  padding: 0;
  border: 0;
  /* display: inline-block;   */
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: relative;
  box-sizing: border-box;  
  cursor: pointer;
}

.favorite_icon{
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: fill;
}

.side_bar_list_amenities{
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.side_bar_list_amenities li{
  list-style: none;
  margin-top: 0;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
}

  
.list_check_mark{

  display: inline-block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+DQogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgPGNpcmNsZSBmaWxsPSIjMkQyRDJEIiBjeD0iMTAiIGN5PSIxMCIgcj0iOSIvPg0KICAgIDxwYXRoIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNNiAxMGwzIDMgNS02Ii8+DQogIDwvZz4NCjwvc3ZnPg==);
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-right: 10px;
  border-radius: 100%;
}


.selected_color{
  border:hsl(0, 0%, 0%) 1px solid
}

.selected_size {
  color: white;
  background-color: black;
}



